import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, type HistoryState, Outlet, useRouter } from '@tanstack/react-router'
import { List } from 'antd-mobile'
import { AddSquareOutline } from 'antd-mobile-icons'
import { Header } from 'components/Header'
import { PopupPage } from '~/components/PopupPage'
import { fetchListsQueryOptions } from '~/database/models/list'

type List = {
  id: string;
  name: string;
}

export const Route = createFileRoute('/_authenticated/items/lists/$itemType/choose')({
  loader: async ({ context: { queryClient, } }) => {
    await queryClient.ensureQueryData(fetchListsQueryOptions)
  },
  component: ChoosePage,
})

function ChoosePage() {
  const router = useRouter()
  const { data: itemTypes } = useSuspenseQuery(fetchListsQueryOptions)

  const onChoose = (itemType: List, state: HistoryState) => {
    router.navigate({
      from: Route.fullPath,
      to: '../../$itemType',
      params: { itemType: itemType.id as string },
      search: search => search as never,
      state
    })
  }

  return (
    <PopupPage
      direction="left"
      width='70vw'
      redirectFrom={Route.fullPath}
    >
      {(state: HistoryState) => (
        <>
          <Header
            title='Lists'
            rightActions={
              <AddSquareOutline
                fontSize={24}
                onClick={() =>
                  router.navigate({
                    from: Route.fullPath,
                    to: './add',
                    search: search => search as never,
                  })
                }
              />
            }
          />
          <List>
            {itemTypes.map(itemType => (
              <List.Item clickable key={itemType.id} onClick={() => onChoose(itemType, state)}>
                {itemType.name}
              </List.Item>
            ))}
          </List>
          <Outlet />
        </>
      )
      }
    </PopupPage >
  )
}

import { createFileRoute, useRouter } from '@tanstack/react-router'
import { Button, Form, Input, type InputRef, Popup } from 'antd-mobile'
import { useEffect, useRef, useState } from 'react'
import { useAddListMutation } from '~/database/models/list'

export const Route = createFileRoute('/_authenticated/items/lists/$itemType/choose/add')({ component: AddItemTypePage })

interface FormValues {
  name: string
}

function AddItemTypePage() {
  const router = useRouter()
  const addList = useAddListMutation();

  const inputRef = useRef<InputRef>(null)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  const onAdd = async (values: FormValues) => {
    addList.mutate({ name: values.name })

    router.invalidate()
    onClose()
  }

  const focusInput = () => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 10)
  }

  return (
    <>
      <Popup
        visible={open}
        onMaskClick={onClose}
        onClose={onClose}
        afterClose={() => {
          router.navigate({
            from: Route.fullPath,
            to: '../',
            search: search => search as never,
          })
        }}
        afterShow={focusInput}
        bodyStyle={{ height: '40vh' }}
      >
        <Form
          initialValues={{ name: '' } as FormValues}
          layout="horizontal"
          onFinish={onAdd}
          footer={
            <Button block type="submit" color="primary" size="large">
              Add
            </Button>
          }
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Category name is required',
              },
            ]}
          >
            <Input ref={inputRef} placeholder="Category" />
          </Form.Item>
        </Form>
      </Popup >
    </>
  )
}

import { type QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { SafeArea } from 'antd-mobile';
import { ErrorBoundary } from "react-error-boundary";
import styled from 'styled-components';
import { ErrorPage } from '~/components/ErrorPage';
import { PopupCloser } from '~/components/PopupCloser';


const Page = styled.div`
  height: 100svh;
`

interface RouterContext {
  queryClient: QueryClient
}

const RootRoute = () => {
  return (
    <Page>
      <SafeArea position="top" />
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Outlet />
        <PopupCloser />
        {/* <TanStackRouterDevtools /> */}
      </ErrorBoundary>
      <SafeArea position="bottom" />
    </Page>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({ component: RootRoute })

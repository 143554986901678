import { GoogleLogin } from '@react-oauth/google';
import { useRouter } from '@tanstack/react-router';
import { supabase } from '~/database/client';

export const LoginButtons = () => {
  const router = useRouter();

  const handleFinishLogin = () => {
    router.navigate({
      to: '/items/lists/$itemType',
      params: { itemType: 'All' },
      search: { filter: 'all' }
    })
  }

  return (
    <GoogleLogin
      onSuccess={async credentialResponse => {
        if (!credentialResponse.credential) {
          throw new Error('No credential response');
        }

        await supabase.auth.signInWithIdToken({
          provider: 'google',
          token: credentialResponse.credential,
        })

        handleFinishLogin();
      }}
      onError={() => {
        throw new Error('No credential response');
      }}
      useOneTap
    />
  )
}
import { Outlet, createFileRoute, useMatchRoute, useNavigate } from '@tanstack/react-router'
import { TabBar } from 'antd-mobile'
import { ShopbagOutline, TagOutline } from 'antd-mobile-icons'
import styled from 'styled-components'

export const Route = createFileRoute('/_authenticated/items')({ component: ItemsPage })

const Page = styled.div`
  height: 100svh;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  height: calc(100% - 150px);
`

const StyledTabBar = styled(TabBar)`
  flex: 0;
  border-top: 1px solid rgb(238, 238, 238);
  padding-bottom: 24px;
  padding-top: 10px;
  height: 150px;
  background-color: white;
  z-index: 999;
`

function ItemsPage() {
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();

  const tabs = [
    {
      key: '/items/lists/all',
      icon: <TagOutline />,
      title: 'Items',
    },
    {
      key: '/items/shoppinglist',
      icon: <ShopbagOutline />,
      title: 'Shopping List',
    },
  ]

  const switchTab = (key: string) => {
    navigate({ to: key })
  }

  const activeTab = tabs.find(item => matchRoute({ to: item.key }))?.key;

  return (
    <Page>
      <Content>
        <Outlet />
      </Content>
      <StyledTabBar
        activeKey={activeTab}
        onChange={key => switchTab(key)}
      >
        {tabs.map(item => (
          <TabBar.Item
            key={item.key}
            icon={item.icon}
            title={item.title}
          />
        ))}
      </StyledTabBar>
    </Page>
  )
}
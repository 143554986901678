import { useNavigate } from "@tanstack/react-router";
import { Button, ErrorBlock } from "antd-mobile";
import styled from "styled-components";
import logo from '~/assets/shs_logo.svg';

const BackButton = styled(Button)`
  margin-top: 24px;
`

export const ErrorPage = ({
  error,
  resetErrorBoundary,
}: {
  error?: unknown;
  resetErrorBoundary?: () => void;
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (resetErrorBoundary) {
      resetErrorBoundary();
    }

    navigate({
      to: '/items/lists/$itemType',
      params: { itemType: 'All' },
      search: { filter: 'all' }
    })
  }

  let errorMessage = 'An unknown error has occurred';

  if (error instanceof Error) {
    errorMessage = error.message
  }

  if (typeof error === 'string') {
    errorMessage = error
  }

  return (
    <ErrorBlock
      fullPage
      image={logo}
      title="An error has occurred"
      style={{ '--image-height': '150px', }}
      description={
        <>
          <p> {errorMessage} </p>
          <br />
          <p>
            Our team has been notified and we&apos;re on it!
          </p>
        </>
      }
    >
      <BackButton color='primary' onClick={goBack}>
        Go Back Home
      </BackButton>
    </ErrorBlock>
  )
}
import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { supabase } from '../client'
import { type Tables } from '../types'

type ShoppingListItem = Tables<'ShoppingListItem'>

export const shoppingListQueryOptions = queryOptions({
  queryKey: ['shoppingList'],
  queryFn: async () => {
    const { data, error } = await supabase.from('ShoppingListItem').select('*').order('addedOn', { ascending: true })

    if (error) {
      throw error
    }

    return data
  },
})

export const useAddShoppingListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: { name: string }) => {
      const existingItems = await queryClient.ensureQueryData(shoppingListQueryOptions)
      const existingItem = existingItems.find(item => item.name === values.name)

      if (existingItem?.userId) {
        const { data, error } = await supabase
          .from('ShoppingListItem')
          .update({ quantity: existingItem.quantity })
          .eq('id', existingItem.id)
          .select()

        if (error) {
          throw error
        }

        return data[0]
      } else {
        const { data, error } = await supabase
          .from('ShoppingListItem')
          .insert({
            name: values.name,
            quantity: 1,
          })
          .select()

        if (error) {
          throw error
        }

        return data[0]
      }
    },

    onMutate: async (values: { name: string }) => {
      const queryKey = ['shoppingList']

      await queryClient.cancelQueries({ queryKey })
      const snapshots = queryClient.getQueriesData<ShoppingListItem[]>({ queryKey })

      const existingItems = await queryClient.ensureQueryData(shoppingListQueryOptions)
      const existingItem = existingItems.find(item => item.name === values.name)

      if (existingItem) {
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, old =>
          (old || []).map(item => {
            if (item.id === existingItem.id) {
              return {
                ...item,
                quantity: item.quantity + 1,
              }
            }

            return item
          })
        )
      } else {
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, old => [
          ...(old || []),
          {
            ...values,
            id: uuidv4(),
            quantity: 1,
          } as ShoppingListItem,
        ])
      }

      return { snapshots }
    },
    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) =>
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, snapshot)
      )
    },
    onSettled: data => {
      if (!data) {
        return
      }

      queryClient.invalidateQueries({ queryKey: ['shoppingList'] })
    },
  })
}

export const useUpdateShoppingListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: ShoppingListItem) => {
      const { data, error } = await supabase.from('ShoppingListItem').update(values).eq('id', values.id).select()

      if (error) {
        throw error
      }

      return data[0]
    },

    onMutate: async (values: ShoppingListItem) => {
      const queryKey = ['shoppingList']

      await queryClient.cancelQueries({ queryKey })
      const snapshots = queryClient.getQueriesData<ShoppingListItem[]>({ queryKey })

      queryClient.setQueryData<ShoppingListItem[]>(queryKey, old =>
        (old || []).map(item => {
          if (item.id === values.id) {
            return values
          }

          return item
        })
      )

      return { snapshots }
    },

    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) =>
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, snapshot)
      )
    },
  })
}

export const useDeleteShoppingListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: ShoppingListItem) => {
      const { data, error } = await supabase.from('ShoppingListItem').delete().eq('id', values.id).select()

      if (error) {
        throw error
      }

      return data[0]
    },

    onMutate: async (values: ShoppingListItem) => {
      const queryKey = ['shoppingList']

      await queryClient.cancelQueries({ queryKey })
      const snapshots = queryClient.getQueriesData<ShoppingListItem[]>({ queryKey })

      queryClient.setQueryData<ShoppingListItem[]>(queryKey, old => (old || []).filter(item => item.id !== values.id))

      return { snapshots }
    },

    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) =>
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, snapshot)
      )
    },
  })
}

export const useClearShoppingList = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: { onlyCompleted: boolean }) => {
      let query = supabase.from('ShoppingListItem').delete()

      if (values.onlyCompleted) {
        query = query.eq('complete', true)
      } else {
        query = query.not('id', 'is', null)
      }

      const { data, error } = await query.select()

      if (error) {
        throw error
      }

      return data
    },

    onMutate: async (values: { onlyCompleted: boolean }) => {
      const queryKey = ['shoppingList']

      await queryClient.cancelQueries({ queryKey })
      const snapshots = queryClient.getQueriesData<ShoppingListItem[]>({ queryKey })

      queryClient.setQueryData<ShoppingListItem[]>(queryKey, old =>
        (old || []).filter(item => (values.onlyCompleted ? !item.complete : false))
      )

      return { snapshots }
    },

    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) =>
        queryClient.setQueryData<ShoppingListItem[]>(queryKey, snapshot)
      )
    },
  })
}

import { List, SwipeAction } from "antd-mobile"
import { AddCircleOutline, MinusCircleOutline, ShopbagOutline } from "antd-mobile-icons"
import styled from "styled-components"
import listAddInstructions from "~/assets/list_add_instructions.svg"
import listInstructions from "~/assets/list_instructions.svg"

const HelpImage = styled.img`
  width: 100%;
`

const AddHelpImage = styled(HelpImage)`
  position: absolute;
  left: 0px;
  bottom: 128px;
`

export const ListWelcome = () => {
  return (
    <>
      <List>
        <SwipeAction
          closeOnAction={false}
          leftActions={[{
            key: 'shoppingCart',
            text: <ShopbagOutline fontSize={20} />,
            color: 'primary',
          }]}
          rightActions={[
            {
              key: 'remove',
              text: <MinusCircleOutline fontSize={20} />,
              color: 'warning',
            },
            {
              key: 'add',
              text: <AddCircleOutline fontSize={20} />,
              color: 'success',
            },
          ]}
        >
          <List.Item
            clickable
            extra={2}
          >
            Example Item
          </List.Item>
        </SwipeAction>
      </List>
      <HelpImage src={listInstructions} />
      <AddHelpImage src={listAddInstructions} />
    </>
  )
}
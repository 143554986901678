import { type QueryKey, queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'
import { queryClient } from '~/main'
import { supabase } from '../client'
import { type Tables } from '../types'

type ListItem = Tables<'ListItem'>

const itemSearchSchema = z.object({ filter: z.string().default('all') })

type ItemSearch = z.infer<typeof itemSearchSchema>

export const listItemsQueryOptions = (listId: string, filter: ItemSearch['filter']) =>
  queryOptions({
    queryKey: ['listItems', listId, { filter }],
    queryFn: async () => {
      const { data: itemTypeItem } = await supabase.from('List').select('*').eq('id', listId).single()

      let query = supabase.from('ListItem').select('*')

      if (itemTypeItem) {
        query = query.eq('listId', itemTypeItem.id)
      }

      query = query.order('name', { ascending: true })

      const { data, error } = await query.order('createdAt', { ascending: true })

      if (error) {
        throw error
      }

      return [...(data || [])]
    },
    select: data => {
      if (filter === 'in-stock') {
        return data.filter(item => item.quantity > 0)
      } else if (filter === 'out-of-stock') {
        return data.filter(item => item.quantity === 0)
      }

      return data
    },
  })

export const listItemQueryOptions = (itemId: string) =>
  queryOptions({
    queryKey: ['listItem', itemId],
    queryFn: async () => {
      const { data, error } = await supabase.from('ListItem').select('*').eq('id', itemId).single()

      if (error) {
        throw error
      }

      return data
    },
    initialData: () => {
      const results = queryClient.getQueriesData<ListItem[]>({ queryKey: ['listItems'] })
      for (const [_queryKey, items] of results) {
        for (const item of items || []) {
          if (item.id === itemId) {
            return item
          }
        }
      }
    },
    initialDataUpdatedAt: () => queryClient.getQueryState(['listItems'])?.dataUpdatedAt,
  })

export const useAddListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: { listId: string; name: string }) => {
      const { data, error } = await supabase
        .from('ListItem')
        .insert({
          listId: values.listId,
          name: values.name,
          quantity: 1,
        })
        .select()

      if (error) {
        throw error
      }

      return data[0]
    },
    onMutate: async (values: { listId: string; name: string }) => {
      const queryKey = ['listItems', values.listId]

      await queryClient.cancelQueries({ queryKey: ['listItems'] })
      const snapshot = queryClient.getQueriesData<ListItem[]>({ queryKey })

      ;[
        ['listItems', values.listId],
        ['listItems', 'all'],
      ].forEach(queryKey =>
        queryClient.setQueriesData<ListItem[]>({ queryKey }, old => [
          ...(old || []),
          {
            ...values,
            id: uuidv4(),
            quantity: 1,
          } as ListItem,
        ])
      )

      return { snapshot }
    },
    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshot.forEach(([queryKey, snapshot]) => queryClient.setQueryData<ListItem[]>(queryKey, snapshot))
    },
    onSettled: data => {
      if (!data) {
        return
      }

      queryClient.invalidateQueries({ queryKey: ['listItems', data.listId] })
    },
  })
}

export const useUpdateListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (item: ListItem) => {
      if (item.quantity && item.quantity < 0) {
        return
      }

      const { data, error } = await supabase
        .from('ListItem')
        .update({ ...item })
        .eq('id', item.id)
        .select()

      if (error) {
        throw error
      }

      return data[0]
    },

    onMutate: async (item: ListItem) => {
      if (item.quantity && item.quantity < 0) {
        return
      }

      const queryKey = ['listItem', item.id]

      await queryClient.cancelQueries({ queryKey: ['listItem'] })
      let snapshots: [QueryKey, unknown][] = queryClient.getQueriesData<ListItem>({ queryKey })
      snapshots = [...snapshots, ...queryClient.getQueriesData<ListItem[]>({ queryKey: ['listItems', item.listId] })]

      queryClient.setQueryData<ListItem>(
        queryKey,
        old =>
          ({
            ...old,
            ...item,
          } as ListItem)
      )
      ;[
        ['listItems', item.listId],
        ['listItems', 'all'],
      ].forEach(queryKey =>
        queryClient.setQueriesData<ListItem[]>({ queryKey }, old =>
          (old || []).map(oldItem => {
            console.log(oldItem, item)
            if (oldItem.id === item.id) {
              return {
                ...oldItem,
                ...item,
              }
            }

            return oldItem
          })
        )
      )

      return { snapshots }
    },

    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) => queryClient.setQueryData<unknown>(queryKey, snapshot))
    },

    onSettled: data => {
      if (!data) {
        return
      }

      queryClient.invalidateQueries({ queryKey: ['listItem', data.id] })
    },
  })
}

export const useDeleteItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: { item: ListItem }) => {
      const { error } = await supabase.from('ListItem').delete().eq('id', values.item.id)

      if (error) {
        throw error
      }

      return values.item
    },

    onMutate: async (values: { item: ListItem }) => {
      const queryKey = ['listItem', values.item.id]

      await queryClient.cancelQueries({ queryKey })
      let snapshots: [QueryKey, unknown][] = queryClient.getQueriesData<ListItem>({ queryKey })
      snapshots = [
        ...snapshots,
        ...queryClient.getQueriesData<ListItem[]>({ queryKey: ['listItems', values.item.listId] }),
      ]

      queryClient.removeQueries({ queryKey })
      ;[
        ['listItems', values.item.listId],
        ['listItems', 'all'],
      ].forEach(queryKey =>
        queryClient.setQueriesData<ListItem[]>({ queryKey }, old => [
          ...(old || []).filter(item => item.id !== values.item.id),
        ])
      )

      return { snapshots }
    },

    onError: (_err, _listItem, context) => {
      if (!context) {
        return
      }

      context.snapshots.forEach(([queryKey, snapshot]) => queryClient.setQueryData<unknown>(queryKey, snapshot))
    },

    onSettled: data => {
      if (!data) {
        return
      }

      queryClient.invalidateQueries({ queryKey: ['listItems', data.listId] })
    },
  })
}

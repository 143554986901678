import ReactDOM from 'react-dom/client';
import 'sentry';

import { RouterProvider, createRouter } from '@tanstack/react-router';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Providers } from './components/Providers';
import { routeTree } from './routeTree.gen';

export const queryClient = new QueryClient()

const router = createRouter({
  routeTree,
  context: { queryClient, }
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  interface HistoryState {
    fromPopupDirection?: 'left' | 'right' | 'top' | 'bottom';
    fromPopupWidth?: string;
    fromPopupHeight?: string;
  }
}

function InnerApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Providers>
    <InnerApp />
  </Providers>
)

import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { supabase } from '../client'
import { type Tables } from '../types'

type List = Tables<'List'>

export const fetchListsQueryOptions = queryOptions({
  queryKey: ['lists'],
  queryFn: async () => {
    const itemTypes = await supabase.from('List').select('*').order('name')

    return [
      {
        id: 'all',
        name: 'All',
      },
      ...(itemTypes.data || []),
    ]
  },
})

export const fetchListQueryOptions = (listId: string) =>
  queryOptions({
    queryKey: ['list', { listId }],
    queryFn: async () => {
      const { data } = await supabase.from('List').select('*').eq('id', listId).single()

      if (!data) {
        return {
          id: 'all',
          name: 'All',
        }
      }

      return data
    },
  })

export const useAddListMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values: { name: string }) => {
      const { data, error } = await supabase.from('List').insert({ name: values.name }).select()

      if (error) {
        throw error
      }

      return data[0]
    },
    onSuccess: data => {
      queryClient.setQueryData<List[]>(['lists'], old => [...(old || []), data])
    },
  })
}

import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, useParams, useRouter } from '@tanstack/react-router'
import { Button, Form, Input, Popup, Stepper } from 'antd-mobile'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { listItemQueryOptions, useDeleteItem, useUpdateListItem } from '~/database/models/listItem'

export const Route = createFileRoute('/_authenticated/items/lists/$itemType/$itemId')({
  loader: async ({ params: { itemId }, context: { queryClient } }) => {
    await queryClient.ensureQueryData(listItemQueryOptions(itemId));
  },
  component: EditItemPage,
})

interface FormValues {
  name: string
  quantity: number
}

const StyledPopup = styled(Popup)`
  .adm-popup-body {
    height: 40vh;
  }
`
const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .adm-list {
    flex: 1;
  }
`

const DeleteButton = styled(Button)`
  margin-bottom: 1rem;
`

const QuantityStepper = styled(Stepper)`
  width: 100%;
`

function EditItemPage() {
  const router = useRouter()
  const params = useParams({ from: Route.id })
  const { data: item } = useSuspenseQuery(listItemQueryOptions(params.itemId))
  const updateItem = useUpdateListItem();
  const deleteItem = useDeleteItem();

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const onSave = async (values: FormValues) => {
    updateItem.mutate({
      ...item,
      ...values,
    }, {
      onSuccess: () => {
        onClose();
      }
    })
  }

  const onClose = () => {
    setOpen(false)
  }

  const onDelete = async () => {
    deleteItem.mutate({ item });
    onClose()
  }

  return (
    <StyledPopup
      visible={open}
      onMaskClick={onClose}
      onClose={onClose}
      afterClose={() => {
        router.navigate({
          from: Route.fullPath,
          to: '../',
          search: ({ filter }) => ({ filter }) as never
        })
      }}
    >
      <StyledForm
        initialValues={
          {
            name: item?.name || '',
            quantity: item?.quantity || 0,
          } as FormValues
        }
        layout="horizontal"
        onFinish={onSave}
        footer={
          <>
            <DeleteButton block color="danger" size="large" onClick={onDelete}>
              Delete
            </DeleteButton>
            <Button block type="submit" color="primary" size="large">
              Save
            </Button>
          </>
        }
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Item name is required',
            },
          ]}
        >
          <Input placeholder="Item" />
        </Form.Item>
        <Form.Item name="quantity">
          <QuantityStepper />
        </Form.Item>
      </StyledForm>
    </StyledPopup>
  )
}

import { NavBar } from 'antd-mobile';
import styled from 'styled-components';

const StyledNavBar = styled(NavBar) <{ $hasBorder: boolean }>`
  background-color: white;
  border-bottom: ${props => props.$hasBorder ? "1px solid rgb(238, 238, 238)" : "none"};
  z-index: 999;
`

export const Header = ({
  leftActions,
  rightActions,
  title,
  style,
  className
}: {
  title?: string;
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}) => {
  return (
    <StyledNavBar
      style={style}
      className={className}
      $hasBorder={!!title}
      backIcon={null}
      back={leftActions || null}
      right={rightActions}
    >
      {title && <h1>{title}</h1>}
    </StyledNavBar>
  )
}
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Outlet, useParams, useRouter, useSearch } from '@tanstack/react-router'
import { FloatingBubble, Input } from 'antd-mobile'
import { AddCircleOutline, AddOutline, MinusCircleOutline, ShopbagOutline, UnorderedListOutline } from 'antd-mobile-icons'
import { FilterButton } from 'components/FilterButton'
import { useState } from 'react'
import styled from 'styled-components'
import { z } from 'zod'
import { ItemsListPage } from '~/components/ItemsListPage'
import { ListWelcome } from '~/components/ListWelcome'
import { fetchListQueryOptions } from '~/database/models/list'
import { listItemsQueryOptions, useUpdateListItem } from '~/database/models/listItem'
import { useAddShoppingListItem } from '~/database/models/shoppinglist'
import { type Tables } from '~/database/types'

const itemSearchSchema = z.object({ filter: z.string().default('all'), })

const AddBubble = styled(FloatingBubble)`
  --initial-position-bottom: 96px;
  --initial-position-right: 24px;
  --edge-distance: 24px;
`

type ListItem = Tables<"ListItem">;

export const Route = createFileRoute('/_authenticated/items/lists/$itemType')({
  validateSearch: (search) => itemSearchSchema.parse(search),
  loaderDeps: ({ search: { filter } }) => ({ filter }),
  loader: async ({ params: { itemType }, deps: { filter }, context: { queryClient } }) => {
    await queryClient.ensureQueryData(fetchListQueryOptions(itemType));
    await queryClient.ensureQueryData(listItemsQueryOptions(itemType, filter))
  },
  component: IndexPage,
})

const Search = styled(Input)`
  border: 1px solid rgb(238, 238, 238);
  border-radius: 25px;
  padding: 8px;
`;

function IndexPage() {
  const router = useRouter()

  const { filter } = useSearch({ from: Route.id })
  const params = useParams({ from: Route.id })

  const [search, setSearch] = useState('');

  const { data: items } = useSuspenseQuery(listItemsQueryOptions(params.itemType, filter))
  const { data: itemType } = useSuspenseQuery(fetchListQueryOptions(params.itemType))

  const updateItem = useUpdateListItem();
  const addShoppingListItem = useAddShoppingListItem();

  const updateQuantity = async (item: ListItem, amount: number) => {
    updateItem.mutate({
      ...item,
      quantity: Math.max(item.quantity + amount, 0),
    })
  }

  const addToShoppingList = async (item: ListItem) => {
    addShoppingListItem.mutate({ name: item.name, })
  }

  return (
    <ItemsListPage
      title={itemType.name}
      emptyComponent={<ListWelcome />}
      hiddenActions={<Search placeholder="Search" value={search} onChange={setSearch} />}
      leftActions={<UnorderedListOutline onClick={() => {
        router.navigate({
          from: Route.fullPath,
          to: './choose',
          search: ({ filter }) => ({ filter }),
        })
      }} />}
      rightActions={
        <FilterButton
          defaultValue="all"
          value={filter}
          onChange={(value: string) =>
            router.navigate({
              to: Route.fullPath,
              search: { filter: value },
              params: { itemType: params.itemType },
            })
          }
          options={[
            {
              label: 'Show all',
              value: 'all',
            },
            {
              label: 'Show in stock',
              value: 'in-stock',
            },
            {
              label: 'Show out of stock',
              value: 'out-of-stock',
            },
          ]}
        />
      }
      items={items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(item => ({
        key: item.id,
        label: item.name,
        extra: item.quantity,
        onClick: () => {
          router.navigate({
            from: Route.fullPath,
            to: './$itemId',
            params: {
              itemType: params.itemType,
              itemId: item.id!,
            },
            search: ({ filter }) => ({ filter }),
          })
        },
        leftActions: [{
          key: 'shoppingCart',
          text: <ShopbagOutline fontSize={20} />,
          color: 'primary',
          onClick: () => addToShoppingList(item),
        }],
        rightActions: [
          {
            key: 'remove',
            text: <MinusCircleOutline fontSize={20} />,
            color: 'warning',
            onClick: () => updateQuantity(item, -1),
          },
          {
            key: 'add',
            text: <AddCircleOutline fontSize={20} />,
            color: 'success',
            onClick: () => updateQuantity(item, 1),
          },

        ]
      }))}>
      <AddBubble>
        <AddOutline
          fontSize={32}
          onClick={() =>
            router.navigate({
              from: Route.fullPath,
              to: './add',
              params: { itemType: params.itemType },
              search: ({ filter }) => ({ filter }),
            })
          }
        />
      </AddBubble>
      <Outlet />
    </ItemsListPage>
  )
}

import { cleanEnv, str as string } from 'envalid'

export class Environment {
  public static config(env: unknown) {
    return cleanEnv(env, {
      MODE: string({
        desc: 'The mode the web is running in',
        example: 'development',
        choices: ['development', 'test', 'production'] as const,
        default: 'development',
        docs: 'https://vitejs.dev/guide/env-and-mode.html',
      }),

      VITE_SUPABASE_URL: string({
        desc: 'The supabase project URL',
        example: 'https://<project>.supabase.co',
      }),

      VITE_SUPABASE_ANON_KEY: string({
        desc: 'The supabase anon key',
        example: '<your-anon-key>',
      }),

      VITE_GOOGLE_CLIENT_ID: string({ desc: 'The Google client ID' }),
    })
  }
}

import { type HistoryState, useRouter } from '@tanstack/react-router';
import { Popup } from 'antd-mobile';
import { useEffect, useState } from 'react';

export function PopupPage({
  direction,
  width,
  height,
  redirectFrom,
  children,
}: {
  direction: 'left' | 'right' | 'top' | 'bottom',
  width?: string;
  height?: string;
  children: (state: HistoryState) => React.ReactNode;
  redirectFrom: string;
}) {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const state = {
    fromPopupDirection: direction,
    fromPopupWidth: direction === 'left' || direction === 'right' ? width : undefined,
    fromPopupHeight: direction === 'top' || direction === 'bottom' ? height : undefined,
  } as const


  useEffect(() => {
    setOpen(true)
  }, [])

  const onClose = () => {
    router.navigate({
      from: redirectFrom,
      to: '..',
      search: search => search,
      state: (state as any)
    })
  }

  return (
    <Popup
      position={direction}
      visible={open}
      onMaskClick={onClose}
      onClose={onClose}
      bodyStyle={{
        width: state.fromPopupWidth,
        height: state.fromPopupHeight,
      }}
    >
      {children(state)}
    </Popup>
  )
}

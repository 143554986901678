import { createFileRoute } from '@tanstack/react-router';
import { Swiper } from 'antd-mobile';
import styled from 'styled-components';
import welcomeImage1 from '~/assets/welcome_image_1.svg';
import { LoginButtons } from '~/components/LoginButtons';

export const Route = createFileRoute('/')({ component: IndexPage })

const PageSwiper = styled(Swiper)`
  height: 100svh;
  width: 100svw;
  padding-top: 48px;
  box-sizing: border-box;

  .adm-swiper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  justify-children: center;
`;

const PageImage = styled.img`
  height: 30%;
  padding-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  padding: 0px 24px;
  text-align: center;
`;

const Instructions = styled.p`
  margin-top: 48px;
  font-size: 1.2rem;
  padding: 0px 24px;
  line-height: 2rem;
  text-align: center;
`;

const Action = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

interface SwipeItemProps {
  key: string;
  image?: string;
  heading: React.ReactNode;
  instructions?: React.ReactNode;
  action?: React.ReactNode;
}

function IndexPage() {
  // const auth = useAuth();
  // const router = useRouter();

  // if (!auth.isLoaded) {
  //   return <></>
  // }

  // if (auth.isSignedIn) {
  //   router.navigate({
  //     to: '/items/lists/$itemType',
  //     params: { itemType: 'All' },
  //     search: { filter: 'all' }
  //   })

  //   return <></>
  // }

  const items: SwipeItemProps[] = [{
    key: 'welcome',
    image: welcomeImage1,
    heading: 'Welcome',
    instructions: 'Swipe to the next page to get started',
  }, {
    key: 'lists',
    image: welcomeImage1,
    heading: 'Create Multiple Lists',
    instructions: 'You can create and organize multiple lists of items to keep track of what\'s important in your household.',
  }, {
    key: 'shopping-list',
    image: welcomeImage1,
    heading: 'Make a Shopping List',
    instructions: 'Easily add items from your lists to your shopping list.',
  }, {
    key: 'get-started',
    heading: 'Get Started',
    instructions: 'Get your household organized starting today!',
    action: <LoginButtons />
  }]

  return (
    <PageSwiper>
      {items.map(item => (
        <Swiper.Item key={item.key}>
          {item.image && <PageImage src={item.image} />}
          <Heading>{item.heading}</Heading>
          {item.instructions && <Instructions>{item.instructions}</Instructions>}
          {item.action && <Action>{item.action}</Action>}
        </Swiper.Item>
      ))}
    </PageSwiper>
  )
}

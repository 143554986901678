import * as Sentry from '@sentry/react';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { supabase } from '~/database/client';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async () => {
    const { data: { session }, error } = await supabase.auth.getSession();

    if (!session || error) {
      throw redirect({ to: '/' })
    }

    Sentry.setUser({
      id: session.user.id,
      email: session.user.email,
    });
  },
  component: () => <Outlet />
})
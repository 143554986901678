import { List, SwipeAction } from "antd-mobile";
import { type Action } from "antd-mobile/es/components/swipe-action";
import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from 'react-in-viewport';
import styled from "styled-components";
import { Header } from "./Header";

type Item = {
  key: string
  label?: string
  leftActions?: Action[]
  rightActions?: Action[]
  extra?: React.ReactNode
  prefix?: React.ReactNode
  onClick?: () => void
  render?: () => React.ReactNode
}

const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  z-index: 999;
`;

const HiddenActions = styled.div<{ $hidden: boolean }>`
  padding: 12px;
  display: flex;
  /* visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')}; */
  display: ${(props) => (props.$hidden ? 'none' : 'block')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-y: auto;
  height: 100%;
`;

const LargeTitle = styled.h1<{ $sticky: boolean }>`
  flex-grow: 0;
  display: block;
  background-color: white;
  font-size: 24px;
  font-weight: 500;
  flex-grow: 0;
  padding: 12px 12px;
  position: ${(props) => (props.$sticky ? 'sticky' : 'static')};
  top: 0px;
`

const StyledList = styled(List)`
  flex-grow: 1;
`;

export const ItemsListPage = ({
  items,
  className,
  style,
  title,
  leftActions,
  rightActions,
  hiddenActions,
  emptyComponent,
  children
}: {
  items: Item[];
  className?: string;
  style?: React.CSSProperties;
  title: string;
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  hiddenActions?: React.ReactNode
  emptyComponent?: React.ReactNode
  children?: React.ReactNode
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenActionsRef = useRef<HTMLDivElement>(null);
  const [isHiddenActionsVisible, setIsHiddenActionsVisible] = useState(true);
  const [hiddenActionsHeight, setHiddenActionsHeight] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);

  const { inViewport: titleInViewport } = useInViewport(ref);

  useEffect(() => {
    if (!hiddenActions) return;
    const y = hiddenActionsRef.current?.getBoundingClientRect().height || 0;

    if (hiddenActionsHeight === 0) {
      setHiddenActionsHeight(y);
    }

    if (containerRef.current && !isInitialized) {
      containerRef.current.scrollTo(0, y)
      setIsInitialized(true)
    }
  }, [hiddenActions, containerRef.current]);

  useEffect(() => {
    if (!isHiddenActionsVisible) return;

    setTimeout(() => {
      containerRef.current?.scrollTo(0, hiddenActionsHeight)
    }, 1)
  }, [isHiddenActionsVisible])

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLDivElement;
    const y = target.scrollTop;

    if (y == 0) {
      setIsHiddenActionsVisible(true);
    }

    if (y > hiddenActionsHeight) {
      setIsHiddenActionsVisible(false);
    }
  }

  return (
    <Page>
      <StyledHeader
        title={titleInViewport ? undefined : title}
        leftActions={leftActions}
        rightActions={rightActions}
      />
      <Container ref={containerRef} className={className} style={style} onScroll={handleScroll}>
        <LargeTitle $sticky={isHiddenActionsVisible} ref={ref}>{title}</LargeTitle>
        <HiddenActions ref={hiddenActionsRef} $hidden={!isHiddenActionsVisible} >
          {hiddenActions}
        </HiddenActions>
        <StyledList>
          {items.length === 0 ? emptyComponent : items.map(item => item.render ? item.render() : (
            <SwipeAction
              key={item.key}
              closeOnAction={false}
              leftActions={item.leftActions}
              rightActions={item.rightActions}
            >
              <List.Item
                clickable
                onClick={item.onClick}
                extra={item.extra}
                prefix={item.prefix}
              >
                {item.label}
              </List.Item>
            </SwipeAction>
          ))
          }
        </StyledList >
        {children}
      </Container>
    </Page>
  )
}
import { useRouterState } from '@tanstack/react-router';
import { Popup } from 'antd-mobile';
import { useEffect, useState } from 'react';

interface TransitionState {
  fromPopupDirection?: 'left' | 'right' | 'top' | 'bottom';
  fromPopupWidth?: string;
  fromPopupHeight?: string;
}

export const PopupCloser = () => {
  const state = useRouterState({ select: s => s.location.state });

  const [open, setOpen] = useState(true);
  const [transitionState, setTransitionState] = useState<TransitionState>();

  useEffect(() => {
    const { fromPopupDirection, fromPopupWidth, fromPopupHeight, ...rest } = state;

    if (!fromPopupDirection) {
      setTransitionState(undefined)
    } else {
      setTransitionState({
        fromPopupDirection, fromPopupWidth, fromPopupHeight
      });
      setOpen(false);
      window.history.replaceState({ ...rest }, '')
    }
  }, [state.key])

  const handleClose = () => {
    setTransitionState(undefined);
    setOpen(true);
  }

  return (
    <Popup
      bodyStyle={{
        width: transitionState?.fromPopupDirection === 'left' || transitionState?.fromPopupDirection === 'right' ? transitionState?.fromPopupWidth : undefined,
        height: transitionState?.fromPopupDirection === 'left' || transitionState?.fromPopupDirection === 'right' ? transitionState?.fromPopupHeight : undefined,
      }}
      position={transitionState?.fromPopupDirection}
      visible={open}
      style={{ display: transitionState !== undefined ? 'block' : 'none' }}
      afterClose={handleClose}
    >

    </Popup>
  )

}
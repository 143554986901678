import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, useParams, useRouter } from '@tanstack/react-router'
import { Button, Form, Input, type InputRef, List, Picker, Popup } from 'antd-mobile'
import { useEffect, useRef, useState } from 'react'
import { fetchListsQueryOptions } from '~/database/models/list'
import { useAddListItem } from '~/database/models/listItem'

export const Route = createFileRoute('/_authenticated/items/lists/$itemType/add')({
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData(fetchListsQueryOptions);
  },
  component: AddItemPage
})

interface FormValues {
  name: string
}

function AddItemPage() {
  const router = useRouter()
  const params = useParams({ from: Route.id })
  const addItem = useAddListItem();

  const lists = useSuspenseQuery(fetchListsQueryOptions).data.filter(list => list.id !== 'all')

  const [listId, setListId] = useState(params.itemType !== 'all' ? params.itemType : lists[0]?.id)
  const [form] = Form.useForm<FormValues>()

  const inputRef = useRef<InputRef>(null)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  const onAdd = async (values: FormValues) => {
    addItem.mutate({
      name: values.name,
      listId,
    })
    form.resetFields()
  }

  const focusInput = () => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 10)
  }

  return (
    <>
      <Popup
        forceRender
        visible={open}
        onMaskClick={onClose}
        onClose={onClose}
        afterClose={() => {
          router.navigate({
            from: Route.fullPath,
            to: '../',
            search: ({ filter }) => ({ filter }) as never
          })
        }}
        afterShow={focusInput}
        bodyStyle={{ height: '40vh' }}
      >
        <Form
          form={form}
          initialValues={{ name: '' } as FormValues}
          layout="horizontal"
          onFinish={onAdd}
          footer={
            <Button block type="submit" color="primary" size="large">
              Add
            </Button>
          }
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Item name is required',
              },
            ]}
          >
            <Input autoFocus ref={inputRef} placeholder="Item" />
          </Form.Item>
          {params.itemType === 'all' && (
            <Form.Item>
              <Picker
                confirmText="Choose"
                cancelText="Cancel"
                value={[listId]}
                onConfirm={value => setListId(value[0] as string || listId)}
                columns={[lists.map(list => ({
                  label: list.name,
                  value: list.id
                }))]}
              >
                {(items, { open }) => {
                  return (
                    <List>
                      <List.Item onClick={open}>
                        {items.every(item => item === null)
                          ? 'Choose a list'
                          : lists.find(list => list.id === listId)?.name
                        }
                      </List.Item>
                    </List>
                  )
                }}
              </Picker>
            </Form.Item>
          )}
        </Form>
      </Popup >
    </>
  )
}

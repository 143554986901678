import { Badge, Checkbox, List, Popup } from 'antd-mobile'
import { FilterOutline } from 'antd-mobile-icons'
import { useState } from 'react'
import styled from 'styled-components'

interface Option {
  label: string
  value: string
}

const StyledPopup = styled(Popup)`
  .adm-popup-body {
    padding-bottom: 24px;
  }
`;

export const FilterButton = ({
  defaultValue,
  value,
  options,
  onChange,
}: {
  defaultValue: string
  value: string
  options: Option[]
  onChange: (value: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const selectOption = (value: string) => {
    onChange(value)
    setIsOpen(false)
  }

  return (
    <>
      <Badge content={value !== defaultValue ? Badge.dot : null}>
        <FilterOutline fontSize={20} onClick={() => setIsOpen(true)} />
      </Badge>
      <StyledPopup position="bottom" visible={isOpen} onMaskClick={() => setIsOpen(false)}>
        <List>
          {options.map(option => (
            <List.Item
              key={option.value}
              arrowIcon={false}
              prefix={<Checkbox
                checked={option.value === value}
              />}
              onClick={() => selectOption(option.value)}
            >
              {option.label}
            </List.Item>
          ))}
        </List>
      </StyledPopup>
    </>
  )
}

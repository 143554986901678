import { createFileRoute, useRouter } from '@tanstack/react-router';
import { List, Popup } from 'antd-mobile';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClearShoppingList } from '~/database/models/shoppinglist';

export const Route = createFileRoute('/_authenticated/items/shoppinglist/options')({ component: ShoppingListOptions, })

const StyledPopup = styled(Popup)`
  .adm-popup-body {
    padding-bottom: 24px;
  }
`;

function ShoppingListOptions() {
  const router = useRouter()
  const [open, setOpen] = useState(false)

  const clearShoppingList = useClearShoppingList();

  useEffect(() => {
    setOpen(true)
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  const onClear = async (onlyCompleted: boolean) => {
    clearShoppingList.mutate({ onlyCompleted })
    onClose();
  }

  return (
    <StyledPopup
      visible={open}
      onMaskClick={onClose}
      onClose={onClose}
      afterClose={() => {
        router.navigate({
          from: Route.fullPath,
          to: '../',
          search: search => search as never,
        })
      }}
    >
      <List>
        <List.Item onClick={() => onClear(false)}>Clear All Items</List.Item>
        <List.Item onClick={() => onClear(true)}>Clear Completed Items</List.Item>
      </List>
    </StyledPopup>
  )
}

import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Outlet, useRouter } from '@tanstack/react-router'
import { Checkbox, Form, Input, type InputRef, List } from 'antd-mobile'
import { AddCircleOutline, DeleteOutline, MinusCircleOutline, MoreOutline } from 'antd-mobile-icons'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { ItemsListPage } from '~/components/ItemsListPage'
import { shoppingListQueryOptions, useAddShoppingListItem, useDeleteShoppingListItem, useUpdateShoppingListItem } from '~/database/models/shoppinglist'
import { type Tables } from '~/database/types'

type ShoppingListItem = Tables<'ShoppingListItem'>;

export const Route = createFileRoute('/_authenticated/items/shoppinglist')({
  component: ShoppingList,
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData(shoppingListQueryOptions);
  }
})

const Search = styled(Input)`
  border: 1px solid rgb(238, 238, 238);
  border-radius: 25px;
  padding: 8px;
`;

const AddForm = styled(Form)`
  border: none;

  .adm-list-body {
    border: none;
    outline: none;
  }

`;

const AddInput = styled(Input)`
  input {
    border: none;
    outline: none;
  }
`

const AddListItem = styled(List.Item)`
  .adm-list-item-content-main {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .adm-list-item {
    padding: 0px;
  }
`

type AddItemFormValues = {
  name: string;
}

function ShoppingList() {
  const router = useRouter();
  const addRef = useRef<InputRef>(null);

  const { data: items } = useSuspenseQuery(shoppingListQueryOptions);
  const addItem = useAddShoppingListItem();
  const updateItem = useUpdateShoppingListItem();
  const deleteItem = useDeleteShoppingListItem();

  const [search, setSearch] = useState('');

  const [form] = Form.useForm<AddItemFormValues>()

  const handleAddItem = async (values: AddItemFormValues) => {
    addItem.mutate({ name: values.name })

    form.resetFields();
    setTimeout(() => {
      addRef.current?.focus();
    }, 100)
  }

  const toggleItem = async (item: ShoppingListItem) => {
    updateItem.mutate({
      ...item,
      complete: !item.complete
    })
  }

  const handleDeleteItem = async (item: ShoppingListItem) => {
    deleteItem.mutate(item)
  }

  const updateQuantity = async (item: ShoppingListItem, amount: number) => {
    const quantity = item.quantity + amount

    if (quantity < 0) {
      return
    }

    updateItem.mutate({
      ...item, quantity
    })
  }

  return (
    <ItemsListPage
      title="Shopping List"
      hiddenActions={<Search placeholder="Search" value={search} onChange={setSearch} />}
      rightActions={
        <MoreOutline
          fontSize={24}
          onClick={() => router.navigate({
            from: Route.fullPath,
            to: './options'
          })}
        />
      }
      items={[...items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(item => ({
        key: item.id,
        label: item.name,
        extra: item.quantity,
        onClick: () => toggleItem(item),
        prefix: <Checkbox checked={item.complete} />,
        leftActions: [{
          key: 'delete',
          text: <DeleteOutline />,
          color: 'danger',
          onClick: () => handleDeleteItem(item)
        }],
        rightActions: [{
          key: 'remove',
          text: <MinusCircleOutline fontSize={20} />,
          color: 'warning',
          onClick: () => updateQuantity(item, -1),
        }, {
          key: 'add',
          text: <AddCircleOutline fontSize={20} />,
          color: 'success',
          onClick: () => updateQuantity(item, 1),
        }]
      })), {
        key: 'add',
        render: () => (
          <AddListItem key="add" prefix={<AddCircleOutline fontSize={24} />}>
            <AddForm form={form} onFinish={handleAddItem}>
              <Form.Item name="name">
                <AddInput ref={addRef} placeholder='Add Item' />
              </Form.Item>
            </AddForm>
          </AddListItem>
        )
      }]}
    >
      <Outlet />
    </ItemsListPage>
  )
}